import { createWebHistory, createRouter } from "vue-router";
import AdminLayout from "./components/Layouts/AdminLayout.vue";
import PublicLayout from "./components/Layouts/PublicLayout.vue";
import AuthLayout from "./components/Layouts/AuthLayout.vue";


const routes = [
    {
        path: "",
        redirect: "/TalepOlustur"
    },
    {
        path: "/Anasayfa",
        beforeEnter(to, from, next) {
            window.location.replace("https://www.gelsinteknik.com")
        }
    },
    {
        path: "/WhatsappTalepTakip",
        beforeEnter(to, from, next) {
            window.location.replace("https://wa.me/905396764092?text=Merhaba%20" + from.query.TakipNo + "%20nolu%20talep%20ile%20ilgili%20sorun%20yaşıyorum")
        }
    },
    {
        path: "/WhatsappTalepOlustur",
        beforeEnter(to, from, next) {
            window.location.replace("https://wa.me/905396764092?text=Merhaba%2C%20Talep%20olu%C5%9Ftururken%20sorun%20ya%C5%9F%C4%B1yorum.%20Yard%C4%B1mc%C4%B1%20olur%20musunuz%20%3F%20")
        }
    },
    // {
    //     path: "/",
    //     name: "",
    //     component: PublicLayout,
    //     children: [{
    //         path: "/MainPage",
    //         name: "MainPage",
    //         component: () =>
    //             import("./components/PublicPage/MainPage.vue")
    //     }]
    // },
    {
        path: "/GizlilikPolitikasi",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/GizlilikPolitikasi",
            name: "GizlilikPolitikasi",
            component: () =>
                import("./components/PublicPage/Politikalar ve Sozlesmeler/GizlilikPolitikasi/GizlilikPolitikasi.vue")
        }]
    },
    {
        path: "/Anket/Basarili",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/Anket/Basarili",
            name: "AnketBasarili",
            component: () =>
                import("./components/PublicPage/Anket/AnketBasarili.vue")
        }]
    },
    {
        path: "/Anket/:TalepNo",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/Anket",
            name: "Anket",
            component: () =>
                import("./components/PublicPage/Anket/Anket.vue")
        }
        ]
    },
    { path: "/Auth", redirect: "/Auth/Login" },
    {
        path: "/Auth",
        name: "",
        component: AuthLayout,
        children: [{
            path: "/Auth/Login",
            name: "Login",
            component: () =>
                import("./components/PublicPage/Auth/Login.vue"),
        },
        {
            path: "/Auth/Signup",
            name: "Signup",
            component: () =>
                import("./components/PublicPage/Auth/Signup.vue")
        }
        ]
    },
    {
        path: "/TalepOlustur",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/TalepOlustur",
            name: "TalepOlustur",
            component: () =>
                import("./components/PublicPage/Support/TalepOlustur.vue")
        }]
    },
    {
        path: "/TalepTakip/:TakipNo",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/TalepTakip",
            name: "TalepTakip",
            component: () =>
                import("./components/PublicPage/Support/TalepTakip.vue")
        }]
    },
    { path: "/Odeme", redirect: "/OdemeSayfasi" },
    {
        path: "/Odeme/OdemeSayfasi/:OdemeKod", //:TakipNo
        name: "",
        component: PublicLayout,
        children: [{
            path: "/Odeme/OdemeSayfasi",
            name: "OdemeSayfasi",
            component: () =>
                import("./components/PublicPage/Odeme/OdemeSayfasi.vue")
        },
        {
            path: "/Odeme/OdemeBasarili",
            name: "OdemeBasarili",
            component: () =>
                import("./components/PublicPage/Odeme/OdemeBasarili.vue")
        }
        ]
    },
    { path: "/Admin", redirect: "/Admin/Main" },
    {
        path: "/Admin",
        name: "",
        component: AdminLayout,
        children: [{
            path: "/Admin/Main",
            name: "Main",
            component: () =>
                import("./components/PrivatePage/AdminSayfalari/AdminSayfasi.vue")
        },
        ]
    },
    { path: "/Talep", redirect: "/Talep/Talepler" },
    {
        path: "/Talep",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Talep/Talepler",
                name: "Talepler",
                component: () =>
                    import("./components/PrivatePage/TalepSayfalari/TaleplerSayfasi.vue")
            },
            {
                path: "/Talep/TalepKurye",
                name: "TalepKurye",
                component: () =>
                    import("./components/PrivatePage/TalepSayfalari/TalepKuryeSayfasi.vue")
            },
            {
                path: "/Talep/TalepTeknikServis",
                name: "TalepTeknikServis",
                component: () =>
                    import("./components/PrivatePage/TalepSayfalari/TalepTeknikServisSayfasi.vue")
            },
        ],

    },
    { path: "/Yonetim", redirect: "/Yonetim/Rol/Rol" },
    {
        path: "/Yonetim",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Yonetim/Servisler",
                name: "Servisler",
                component: () =>
                    import("./components/PrivatePage/TeknikServisSayfalari/TeknikServislerSayfasi.vue")
            },
            {
                path: "/Yonetim/Kullanicilar",
                name: "Kullanicilar",
                component: () =>
                    import("./components/PrivatePage/KullaniciSayfalari/KullanicilarSayfasi.vue")
            },
            {
                path: "/Yonetim/Rol/Rol",
                name: "Rol",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Rol/Rol.vue")
            },
            {
                path: "/Yonetim/RolYetki",
                name: "RolYetki",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Rol/RolYetki.vue")
            },
            {
                path: "/Yonetim/Yetki",
                name: "Yetki",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Yetki/Yetki.vue")
            },
            {
                path: "/Yonetim/WebSayfa",
                name: "WebSayfa",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Yetki/WebSayfa.vue")
            },
            {
                path: "/Yonetim/UrunGiris",
                name: "UrunGiris",
                component: () =>
                    import("./components/PrivatePage/Yönetim/UrunGiris/UrunGiris"),
                children: [
                    {
                        path: "/Yonetim/UrunGiris/UrunGenelBakis",
                        name: "UrunGenelBakis",
                        component: () =>
                            import("./components/PrivatePage/Yönetim/UrunGiris/UrunGenelBakis.vue")
                    },
                    {
                        path: "/Yonetim/UrunGiris/Tur",
                        name: "Tur",
                        component: () =>
                            import("./components/PrivatePage/Yönetim/UrunGiris/Tur.vue")
                    },
                    {
                        path: "/Yonetim/UrunGiris/Marka",
                        name: "Marka",
                        component: () =>
                            import("./components/PrivatePage/Yönetim/UrunGiris/Marka.vue")
                    },
                    {
                        path: "/Yonetim/UrunGiris/Model",
                        name: "Model",
                        component: () =>
                            import("./components/PrivatePage/Yönetim/UrunGiris/Model.vue")
                    }],
            },
            {
                path: "/Yonetim/TumTalepler",
                name: "TumTalep",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Talep/TumTalep.vue")
            },
            {
                path: "/Yonetim/TamamlananTalepler",
                name: "TamamlananTalep",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Talep/TamamlananTalep.vue")
            },
            {
                path: "/Yonetim/RedEdilenTalep",
                name: "RedEdilenTalep",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Talep/RedEdilenTalep.vue")
            },
            {
                path: "/Yonetim/Sistem/TabloBoyut",
                name: "TabloBoyut",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Sistem/TabloBoyut.vue")
            },

        ]
    },
    // {
    //     path: "/:catchAll(.*)",
    //     component: () =>
    //         import("./components/PublicPage/ErrorPages/NotFound.vue"),
    // },
    {
        path: "/NotFound",
        component: () =>
            import("./components/PublicPage/ErrorPages/NotFound.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

import GlobalServis from '../src/services/GlobalServis';
import index from './store/index'

router.beforeEach((to, from, next) => {
    if (
        to.href == '/Anasayfa' ||
        //
        // Whatsapp Help
        to.href == '/WhatsappTalepOlustur' || to.href == '/WhatsappTalepTakip' ||
        //
        to.href == "/Auth/Login" ||
        to.href == "/TalepOlustur" ||
        to.href == "/NotFound" ||
        to.href == "/GizlilikPolitikasi" ||
        to.href.match(/Anket*/) || to.href == '/Anket/Basarili' ||
        to.href == "/Odeme/OdemeSayfasi" || to.href == "/Odeme/OdemeBasarili" ||
        to.href.match(/TalepTakip*/) || to.href.match(/OdemeSayfasi*/)
    ) {
        next();
    } else {
        let data = {
            URL: to.href
        }
        GlobalServis.GlobalServis("POST", "GetYetkiKontrol", data).then(
            (res) => {
                if (res.status == 200 && res.data == true) {
                    next();
                } else {
                    if (index.state.auth.status.loggedIn) {
                        next('/NotFound');
                    } else {
                        next('');
                    }
                }
            }
        );
    }
})

router.afterEach(() => {
})

export default router;