<template>
  <div class="body">
    <div>
      <transition name="fade" mode="out-in">
        <ScrollPanel style="width: 100%;" :style="{ 'height': windowHeight + 'px' }" >
          <Menubar :model="items">
            <template #start>
              <img alt="logo" :src="require(`@/assets/logo/logo1.png`)" height="30" class="p-mr-2" />
            </template>
            <template #end>
              <i v-badge="NoficationCount" class="pi pi-bell" style="font-size: 1.2rem" />
            </template>
          </Menubar>
          <router-view />
        </ScrollPanel>
      </transition>
    </div>
  </div>

</template>

<script>

import GlobalServis from "../../services/GlobalServis";
export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      // NotificationBar: false,
      NotificationItems: [],
      NoficationCount: 0,
      // NotificationShow: false,
      tempitems: [],
      items: [],
      chartData: {
        labels: ["A", "B", "C"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
          },
        ],
      },
      // lightOptions: {
      //   plugins: {
      //     legend: {
      //       labels: {
      //         color: "#495057",
      //       },
      //     },
      //   },
      // },
    }
  },
  methods: {
    initServis() {
      GlobalServis.GlobalServis("GET", "GetNavbar", "").then(
        (res) => {
          if (res.status == 200) {
            this._tempitems = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      ).then(() => {
        let temp_ = []
        let counter = 0
        this._tempitems.forEach((value) => {
          if (value.UstMenu == 0) {
            temp_.push({
              label: value.Tanim,
              to: value.Url,
              icon: "pi " + value.IconAd
            })
            let counter2 = 0;
            let _tempSubMenu = []
            this._tempitems.forEach(value2 => {
              if (value.YetkiYeri_rowid == value2.UstMenu) {
                _tempSubMenu.push({
                  label: value2.Tanim,
                  to: value2.Url,
                  icon: "pi " + value2.IconAd,
                })
                temp_[counter].items = _tempSubMenu
                if (value2.Seperator === true) {
                  _tempSubMenu.push({
                    separator: true
                  })
                }
                counter2++;
                temp_[counter].items = _tempSubMenu
              }
            })
            counter++;
          }
        })
        temp_.push({
          label: "Quit",
          // to: "/Cikis",
          command: () => this.Logout(),
          icon: "pi  pi-power-off"
        })
        this.items = temp_
        
      })
    },
    Logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ path: "/Anasayfa" });
      },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$toast.add({
            severity: "warn",
            summary: "Çıkış Hatası",
            detail: this.message,
            life: 3000,
          });
        }
      );
    },
    NotificationControl() {
      this.NotificationShow = false;
      GlobalServis.GlobalServis("GET", "Notify_YeniTalep", "").then(
        (res) => {
          console.log(res);
          
          if (res.status == 200) {
            if (res.data.length > this.NotificationItems.length) {
              this.$toast.add({
                severity: "info",
                summary: "Yeni Bildirim",
                detail: "Onay bekleyen talep var.",
                life: 8000,
              });
            }
            this.NotificationItems = res.data;

            this.NoficationCount = this.NotificationItems.length;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else if (res.response.status == 401) {
            this.Logout();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        })
    },
    onResize() {
      this.windowHeight = window.innerHeight
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.initServis();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    this.interval = setInterval(() => this.NotificationControl(), 10000);
  },
};
</script>

<style lang="scss" scoped>
.body {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  // background-color: var(--gray-300);
   background-color: #EBF2FA;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>